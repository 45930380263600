import React from 'react';
import { Padding, Margin } from 'styled-components-spacing';
import {
  BacteriaGraphicIcon,
  ScalpelGraphicIcon,
  DoctorNurseGraphicIcon,
  HeartbeatGraphicIcon,
  DentalGraphicIcon,
  MoreGraphicIcon,
  ToothShieldGraphicIcon,
  StethoscopeGraphicIcon,
  GlassesGraphicIcon,
  RemedialMassageGraphicIcon,
  PhysioGraphicIcon,
  PharmaceuticalGraphicIcon,
} from '@nib/icons';
import { Section, Tiles } from '@nib/layout';
import Copy from '@nib-components/copy';
import BenefitsTable from '../../../components/BenefitsTable';
import IconPoint from '../../../components/IconPoint';
import { ContentfulDocument } from '../../../constructs/models';
import combineBenefits from '../../../content/combine-benefits';
import { HospitalTabNonPharmacInfoStack, NonPharmacSectionFooter } from './NonPharmacShared';

const CombinePlansDescription = (): JSX.Element => (
  <Padding bottom={6}>
    <Copy measure={false}>
      Combine our plans to get all the advantages of Everyday and Hospital cover in one policy.
      These plans offer cover for:
    </Copy>
  </Padding>
);

const CombinePlansCoveredItems = (): JSX.Element => (
  <Margin bottom={6}>
    <Tiles columns={{ xs: 1, md: 2 }} space={{ xs: 4, md: 6 }}>
      <IconPoint largeText={false} icon={BacteriaGraphicIcon} description="Cancer treatment" />
      <IconPoint
        largeText={false}
        icon={ScalpelGraphicIcon}
        description="Major and minor surgery like hip and knee replacements, skin lesions and GP surgery"
      />
      <IconPoint
        largeText={false}
        icon={DoctorNurseGraphicIcon}
        description="Specialist consultations"
      />
      <IconPoint
        largeText={false}
        icon={HeartbeatGraphicIcon}
        description="Diagnostic investigations like MRI scans, x-rays and CT angiograms"
      />
      <IconPoint
        largeText={false}
        icon={DentalGraphicIcon}
        description="Extraction of impacted and unerupted teeth after you’ve had your policy at least 12 months"
      />
      <IconPoint
        largeText={false}
        icon={ToothShieldGraphicIcon}
        description="Dental treatment like check-ups, fillings, braces and crowns"
      />
      <IconPoint largeText={false} icon={PhysioGraphicIcon} description="Physio" />
      <IconPoint largeText={false} icon={StethoscopeGraphicIcon} description="GP visits" />
      <IconPoint
        largeText={false}
        icon={GlassesGraphicIcon}
        description="New glasses and contact lenses"
      />
      <IconPoint
        largeText={false}
        icon={RemedialMassageGraphicIcon}
        description="Acupuncture and massage"
      />
      <IconPoint
        largeText={false}
        icon={PharmaceuticalGraphicIcon}
        description="Medsafe-approved, non-PHARMAC funded drugs with non-PHARMAC Plus"
      />
      <IconPoint largeText={false} icon={MoreGraphicIcon} description="Plus a whole lot more" />
    </Tiles>
  </Margin>
);

interface CombinePlansSectionProps {
  standardHospitalPolicyDocument: ContentfulDocument;
  premiumHospitalPolicyDocument: ContentfulDocument;
  standardEverydayPolicyDocument: ContentfulDocument;
  premiumEverydayPolicyDocument: ContentfulDocument;
  nonPharmacPlusTermsAndConditionsDocument: ContentfulDocument;
}

export const CombinePlansSection = ({
  standardHospitalPolicyDocument,
  premiumHospitalPolicyDocument,
  standardEverydayPolicyDocument,
  premiumEverydayPolicyDocument,
  nonPharmacPlusTermsAndConditionsDocument,
}: CombinePlansSectionProps): JSX.Element => (
  <Section>
    <CombinePlansDescription />
    <CombinePlansCoveredItems />
    <BenefitsTable
      rows={combineBenefits(
        standardHospitalPolicyDocument,
        premiumHospitalPolicyDocument,
        standardEverydayPolicyDocument,
        premiumEverydayPolicyDocument
      )}
      numberOfColumns={2}
    />
    <HospitalTabNonPharmacInfoStack
      nonPharmacPlusTermsAndConditionsDocument={nonPharmacPlusTermsAndConditionsDocument}
    />
    <NonPharmacSectionFooter />
  </Section>
);
