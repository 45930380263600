import Copy from '@nib-components/copy';
import styled from 'styled-components';

const ListCopy = styled(Copy)`
  > ul > li {
    margin-bottom: 0.5rem;
  }
  > ol {
    padding-left: 1.25rem;
  }
  > ol > li {
    margin-bottom: 0.5rem;
  }
`;

export default ListCopy;
