import React from 'react';
import FeaturePanel from '@nib-components/feature-panel';
import breakpoint from 'styled-components-breakpoint';
import colors from '@nib-components/colors';
import { colorTrueGreen, colorSunsetPink60, colorWhite } from '@nib-components/theme';
import { p, py, px } from 'styled-components-spacing';

import styled from 'styled-components';

const WrappedFeaturePanel = ({ className, children }): JSX.Element => (
  <FeaturePanel className={className} space={1}>
    {children}
  </FeaturePanel>
);

/**
 * FeaturePanel that displays items in a single row as much as possible.
 */
export const PopupFeaturePanel = styled(WrappedFeaturePanel)`
  background-color: ${colorSunsetPink60};
  ${px(0)};
  ${py(0)};
  div:first-child {
    color: ${colorTrueGreen};
  }
  ${breakpoint('md')`
    * > * {
      ${px(2)}
      grid-template-columns: repeat(2,minmax(0,1fr));
    }
  `};
  ${breakpoint('xl')`
    * > * {
      ${px(2)}
      grid-template-columns: repeat(4,minmax(0,1fr));
    }
  `};
`;

const WrappedFeatureStyled = ({ className, children, icon, ...rest }): JSX.Element => (
  <FeaturePanel.Feature className={className} icon={icon} {...rest}>
    {children}
  </FeaturePanel.Feature>
);

/**
 * FeaturePanel.Feature that appears to 'popup' from the page.
 */
export const PopupFeature = styled(WrappedFeatureStyled)`
  ${p(4)};
  > * {
    word-break: break-word;
    margin: 0;
  }
  box-shadow: ${colors.dopey} 0px 4px 8px 0px;
  background-color: ${colorWhite};
`;

PopupFeaturePanel.PopupFeature = PopupFeature;
