import React from 'react';
import { Section } from '@nib/layout';
import UtilityButtons from '../../components/UtilityButtons';
import { UtilityLinkModel } from '../../constructs/models';

export const NavigationSection = (): JSX.Element => {
  const utilityLinks: UtilityLinkModel[] = [
    {
      subtitle: 'Need some help?',
      title: 'Ask us to call you',
      url: '#callback',
    },
    {
      subtitle: 'NZ health insurance?',
      title: 'Learn how it works',
      url: '/health-insurance',
    },
    {
      subtitle: 'Want our latest offer?',
      title: 'Find out more',
      url: '/special-offers',
    },
    {
      subtitle: 'About nib',
      title: 'Find out more',
      url: '/about-nib',
    },
  ];
  return (
    <Section role="section" background="warmWhite60" padding={4}>
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};
