import React from 'react';
import { Padding, Margin } from 'styled-components-spacing';
import {
  BacteriaGraphicIcon,
  ScalpelGraphicIcon,
  DoctorNurseGraphicIcon,
  HeartbeatGraphicIcon,
  DentalGraphicIcon,
  MoreGraphicIcon,
  PharmaceuticalGraphicIcon,
} from '@nib/icons';
import { Section, Tiles } from '@nib/layout';
import Copy from '@nib-components/copy';

import BenefitsTable from '../../../components/BenefitsTable';
import IconPoint from '../../../components/IconPoint';
import { ContentfulDocument } from '../../../constructs/models';
import hospitalBenefits from '../../../content/hospital-benefits';

import { HospitalTabNonPharmacInfoStack, NonPharmacSectionFooter } from './NonPharmacShared';

const HospitalPlanDescription = (): JSX.Element => (
  <Padding bottom={6}>
    <Copy measure={false}>
      Our Hospital plans are designed to help cover the big health costs like surgery or cancer
      treatment, giving you fast access to private healthcare and making it easier to get back on
      your feet. These plans offer cover for:
    </Copy>
  </Padding>
);

const HospitalCoveredItems = (): JSX.Element => (
  <Margin bottom={6}>
    <Tiles columns={{ xs: 1, md: 2 }} space={{ xs: 4, md: 6 }}>
      <IconPoint largeText={false} icon={BacteriaGraphicIcon} description="Cancer treatment" />
      <IconPoint
        largeText={false}
        icon={ScalpelGraphicIcon}
        description="Major and minor surgery like hip and knee replacements, skin lesions and GP surgery"
      />
      <IconPoint
        largeText={false}
        icon={DoctorNurseGraphicIcon}
        description="Specialist consultations"
      />
      <IconPoint
        largeText={false}
        icon={HeartbeatGraphicIcon}
        description="Diagnostic investigations like MRI scans, x-rays and CT angiograms"
      />
      <IconPoint
        largeText={false}
        icon={DentalGraphicIcon}
        description="Extraction of impacted and unerupted teeth after you’ve had your policy at least 12 months"
      />
      <IconPoint
        largeText={false}
        icon={PharmaceuticalGraphicIcon}
        description="Medsafe-approved, non-PHARMAC funded drugs with non-PHARMAC Plus"
      />
      <IconPoint largeText={false} icon={MoreGraphicIcon} description="Plus a whole lot more" />
    </Tiles>
  </Margin>
);

interface HospitalSectionProps {
  standardHospitalPolicyDocument: ContentfulDocument;
  premiumHospitalPolicyDocument: ContentfulDocument;
  nonPharmacPlusTermsAndConditionsDocument: ContentfulDocument;
}

export const HospitalSection = ({
  standardHospitalPolicyDocument,
  premiumHospitalPolicyDocument,
  nonPharmacPlusTermsAndConditionsDocument,
}: HospitalSectionProps): JSX.Element => (
  <Section>
    <HospitalPlanDescription />
    <HospitalCoveredItems />
    <BenefitsTable
      rows={hospitalBenefits(standardHospitalPolicyDocument, premiumHospitalPolicyDocument)}
      numberOfColumns={2}
    />
    <HospitalTabNonPharmacInfoStack
      nonPharmacPlusTermsAndConditionsDocument={nonPharmacPlusTermsAndConditionsDocument}
    />
    <NonPharmacSectionFooter />
  </Section>
);
