import { ContentfulDocument } from '../constructs/models';
import { urlConstants } from '../constructs/constants';

const everydayBenefits = (
  standardEverydayPolicyDocument: ContentfulDocument,
  premiumEverydayPolicyDocument: ContentfulDocument
) => [
  {
    type: 'plans',
    columns: [
      {
        name: 'Standard',
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        note: 'Claim back 60% of costs',
        benefits: [
          {
            productCode: 'IZE',
            subProductCode: 'BASE-IZE',
            membershipCode: 'IZ',
            excess: 0,
          },
        ],
      },
      {
        name: 'Premium',
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        note: 'Claim back 80% of costs',
        benefits: [
          {
            productCode: 'IZF',
            subProductCode: 'BASE-IZF',
            membershipCode: 'IZ',
            excess: 0,
          },
        ],
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Dental – including orthodontics',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$750<div>",
            details:
              '<p>Dental treatments including:</p><ul><li>examinations</li><li>cleaning and scaling</li><li>fillings</li><li>extractions (including wisdom teeth)</li><li>associated x-rays</li><li>orthodontic treatment (e.g. braces)</li><li>endodontic treatment (e.g. root canal)</li></ul><p><b>Waiting periods:</b></p><p><b>Preventative:</b></p><p>2 months (e.g. examinations, scale and clean, fluoride treatments)</p><p><b>General:</b></p><p>2 months (e.g. fillings, basic extraction (excluding wisdom teeth), x-rays)</p><p><b>Major &amp; orthodontic:</b></p><p>12 months (e.g. crowns, bridges, root canal, removal of wisdom teeth, dentures, implants)</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$1000<div>",
            details:
              '<p>Dental treatments including:</p><ul><li>examinations</li><li>cleaning and scaling</li><li>fillings</li><li>extractions (including wisdom teeth)</li><li>associated x-rays</li><li>orthodontic treatment (e.g. braces)</li><li>endodontic treatment (e.g. root canal)</li></ul><p><b>Waiting periods:</b></p><p><b>Preventative:</b></p><p>2 months (e.g. examinations, scale and clean, fluoride treatments)</p><p><b>General:</b></p><p>2 months (e.g. fillings, basic extraction (excluding wisdom teeth), x-rays)</p><p><b>Major &amp; orthodontic:</b></p><p>12 months (e.g. crowns, bridges, root canal, removal of wisdom teeth, dentures, implants)</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Eye care',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of prescription eyewear, and the cost of examination fees</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of prescription eyewear, and the cost of examination fees</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'GP visits & prescriptions',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of GP and nurse practitioner visits and prescriptions.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of GP and nurse practitioner visits and prescriptions.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Physio, chiro & osteo',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of physiotherapy, chiropractic or osteopathic treatment.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of physiotherapy, chiropractic or osteopathic treatment.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Acupuncture, traditional Chinese medicine, remedial massage and vaccinations',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$150<div>",
            details:
              '<p>Covers the cost of acupuncture, traditional Chinese medicine, remedial massage and vaccinations.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$300<div>",
            details:
              '<p>Covers the cost of acupuncture, traditional Chinese medicine, remedial massage and vaccinations.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Speech therapy',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$200<div>",
            details:
              '<p>Covers the cost of speech therapy.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$400<div>",
            details:
              '<p>Covers the cost of speech therapy.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Mental health consultations',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of consultations by a psychiatrist or psychologist.</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of consultations by a psychiatrist or psychologist.</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'footer',
    columns: [
      {
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        documents: [
          {
            title: 'Standard Everyday Cover Policy document',
            url: standardEverydayPolicyDocument?.file?.url,
          },
        ],
      },
      {
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        documents: [
          {
            title: 'Premium Everyday Cover Policy document',
            url: premiumEverydayPolicyDocument?.file?.url,
          },
        ],
      },
    ],
  },
];

export default everydayBenefits;
