import React from 'react';
import { Section, Stack } from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';

import CallBackModal from '../../components/CallBackModal';

export const CallBackSection = (): JSX.Element => {
  return (
    <Section role="section" background="sunsetPink60">
      <Stack space={{ xs: 4, md: 6 }}>
        <Heading id="callback" color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
          Need help? We can call you
        </Heading>
        <Copy measure={false}>
          If you need help deciding on what plan is right for you, give us a call. Choose a time
          below and one of our friendly consultants will call you back, normally within one working
          day (Monday - Friday 8:00am to 5:30pm). Or, speak with a consultant now –{' '}
          <Link href="tel:0800123642">0800 123 642</Link>, option 4.
        </Copy>
        <CallBackModal variant="Expert" isModal={false} backgroundColor="light" />
      </Stack>
    </Section>
  );
};
