import React from 'react';
import { Box, Inline, Section, Stack } from '@nib/layout';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import { LinkWrapper } from '../../../components/BenefitsTable';
import { urlConstants } from '../../../constructs/constants';
import { NonPharmacSummary } from '../../../components/NonPharmacSummary';
import ListCopy from '../../../components/ListCopy';

const HospitalAddOnSectionHeader = () => (
  <Stack>
    <Copy measure={false}>
      An Option adds extra cover to an eligible plan, for an additional cost. You can add an Option
      at any time, not just when you first join nib:
    </Copy>
    <ListCopy measure={false} component="div">
      <ul>
        <li>
          You can choose who the Option is for on your policy — it doesn’t need to apply to everyone
        </li>
        <li>
          If you’re already an nib member, it’s easy to add an Option to your existing policy —
          simply send us your details using our{' '}
          <Link href="https://health.nib.co.nz/contact-us">online form</Link>
        </li>
      </ul>
    </ListCopy>
  </Stack>
);

const HospitalAddonTabNonPharmacInfoStack = ({ nonPharmacPlusTermsAndConditionsDocument }) => (
  <Stack space={{ xs: 2, md: 4 }}>
    <Heading size={2}>Non-PHARMAC Plus</Heading>
    <Copy measure={false}>
      Non-PHARMAC Plus gives you cover for the cost of some medicines that aren’t funded by PHARMAC
      - a government agency who are responsible for deciding which drugs are subsidised as part of
      our public health system.
    </Copy>
    <Copy measure={false}>
      This includes all Medsafe-approved medicines prescribed in line with Medsafe’s guidelines that
      aren’t funded by PHARMAC. Medsafe is a business unit of the Ministry of Health. They’re
      responsible for regulating therapeutic products in New Zealand including medicines, related
      products, medical devices and controlled drugs.
    </Copy>
    <Copy measure={false}>
      Non-PHARMAC medicines are used to treat a number of conditions including cancer, Crohn's
      disease and arthritis. non-PHARMAC Plus covers the cost of these medicines, as long as:
    </Copy>
    <ListCopy measure={false} component="div">
      <ul>
        <li>
          the treatment is eligible for cover under your Hospital plan and we’ve approved a related
          claim for treatment
        </li>
        <li>your specialist has recommended them</li>
        <li>
          the non-PHARMAC medicines are used in a private hospital, or at home up to six months
          after being admitted to private hospital for approved and related treatment.
        </li>
      </ul>
    </ListCopy>
    <Copy measure={false}>
      The Option also covers any medicine administration costs and can be added to eligible Hospital
      plans.
    </Copy>
    <Copy measure={false}>
      There are four different cover levels (the maximum amount we will pay towards your eligible
      claims within your policy year for each member covered) to choose from to suit your needs:
    </Copy>
    <NonPharmacSummary
      nonPharmacTermsAndConditionsLink={nonPharmacPlusTermsAndConditionsDocument.file.url}
    />
    <Copy measure={false}>
      If you’re already an nib member, it’s easy to add an option to your existing policy — simply
      send us your details using our{' '}
      <Link href="https://health.nib.co.nz/contact-us">online form</Link>
    </Copy>
    <Box space={2}>
      <Inline space={{ xs: 2, md: 4 }} collapseBelow="md">
        <SecondaryButton href={urlConstants.nonPharmac}>Learn more</SecondaryButton>
        <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
      </Inline>
    </Box>
    <LinkWrapper>
      *<Link href="#things-to-know">Important things to know</Link>
    </LinkWrapper>
  </Stack>
);

export const HospitalAddOnSection = ({ nonPharmacPlusTermsAndConditionsDocument }) => (
  <Section>
    <HospitalAddOnSectionHeader />
    <HospitalAddonTabNonPharmacInfoStack
      nonPharmacPlusTermsAndConditionsDocument={nonPharmacPlusTermsAndConditionsDocument}
    />
  </Section>
);
