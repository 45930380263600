import Copy from '@nib-components/copy';
import { PharmaceuticalGraphicIcon } from '@nib/icons';
import React, { useEffect, useState } from 'react';
import { PopupFeaturePanel } from '../PopupFeature';
import { getNonPharmacIndictivePrices, NonPharmacLimits } from '../shared/PricingApiService';

const fetchNonPharmacBenefits = async () => {
  const result = await getNonPharmacIndictivePrices();

  const getPolicyByLimit = (policies, limit) => {
    return policies.find(
      (policy) => policy?.members?.[0].productBenefits?.[0]?.limits?.[0].amount === limit
    );
  };

  return NonPharmacLimits.reduce((accu, limit) => {
    const policy = getPolicyByLimit(result?.data?.policies, limit);
    accu[`NonPharmac${limit}`] = policy?.members[0]?.memberPremiumWithGst?.weekly || '0';
    return accu;
  }, {});
};

const DollarAmount = ({ value }: { value: string }) => {
  let parsedAmount = parseFloat(value)?.toFixed(2) || '';
  return <>${parsedAmount}*</>;
};

export type NonPharmacSummaryProps = {
  nonPharmacTermsAndConditionsLink: string;
};

export const NonPharmacSummary = ({}: NonPharmacSummaryProps) => {
  const [benefitPrices, setBenefitPrices] = useState({} as Record<string, string>);
  useEffect(() => {
    fetchNonPharmacBenefits().then((benefits) => setBenefitPrices(benefits));
  }, []);

  return (
    <>
      <PopupFeaturePanel>
        <PopupFeaturePanel.PopupFeature icon={PharmaceuticalGraphicIcon}>
          $50,000 every policy year <br />
          From <DollarAmount value={benefitPrices.NonPharmac50000} /> weekly
        </PopupFeaturePanel.PopupFeature>
        <PopupFeaturePanel.PopupFeature icon={PharmaceuticalGraphicIcon}>
          $100,000 every policy year <br />
          From <DollarAmount value={benefitPrices.NonPharmac100000} /> weekly
        </PopupFeaturePanel.PopupFeature>
        <PopupFeaturePanel.PopupFeature icon={PharmaceuticalGraphicIcon}>
          $200,000 every policy year <br />
          From <DollarAmount value={benefitPrices.NonPharmac200000} /> weekly
        </PopupFeaturePanel.PopupFeature>
        <PopupFeaturePanel.PopupFeature icon={PharmaceuticalGraphicIcon}>
          $300,000 every policy year <br />
          From <DollarAmount value={benefitPrices.NonPharmac300000} /> weekly
        </PopupFeaturePanel.PopupFeature>
      </PopupFeaturePanel>

      <Copy measure={false}>
        This is a summary of the non-PHARMAC cover only. See your Standard Hospital or Premium
        Hospital policy document.
      </Copy>
    </>
  );
};
