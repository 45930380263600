import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { Box, Inline, Stack } from '@nib/layout';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';

import { LinkWrapper } from '../../../components/BenefitsTable';
import { urlConstants } from '../../../constructs/constants';
import { NonPharmacSummary } from '../../../components/NonPharmacSummary';

const NonPharmacAnchorDiv = styled.div`
  display: block;
  position: relative;
  top: 1280px;
  ${breakpoint('sm')`
  top: 1100px;
  `}
  ${breakpoint('md')`
  top: 950px;
  `}
  ${breakpoint('lg')`
    top: 660px;
  `}
`;

export const HospitalTabNonPharmacInfoStack = ({ nonPharmacPlusTermsAndConditionsDocument }) => (
  <Margin top={6}>
    <NonPharmacAnchorDiv id="non-pharmac" />
    <Stack space={{ xs: 2, md: 4 }}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
        Non-PHARMAC Plus
      </Heading>
      <Copy measure={false}>
        Non-PHARMAC funded medicines can be lifesaving – but can cost up to hundreds of thousands of
        dollars. This Option helps you cover the cost and lessen the burden of expensive treatments
        with these drugs for conditions such as cancer, Crohn's disease and arthritis.
      </Copy>
      <Copy measure={false}>
        Non-PHARMAC Plus covers the cost of non-PHARMAC funded medicines that are Medsafe-approved,
        used in a private hospital or at home and meet Medsafe’s guidelines for use<sup>1</sup>. The
        drugs must be recommended by a specialist and relate to an approved claim for treatment
        under your Hospital plan (which covers eligible hospital, specialist and/or anaesthetist
        costs).
      </Copy>
      <Copy measure={false}>
        The Option also covers any medicine administration costs and there are four different cover
        levels (the maximum amount we will pay towards your eligible claims within your policy year
        for each member covered) to choose from to suit your needs:
      </Copy>
      <NonPharmacSummary
        nonPharmacTermsAndConditionsLink={nonPharmacPlusTermsAndConditionsDocument.file.url}
      />
      <Box space={2}>
        <Inline space={{ xs: 2, md: 4 }} collapseBelow="md">
          <SecondaryButton href={urlConstants.nonPharmac}>Learn more</SecondaryButton>
          <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
        </Inline>
      </Box>
    </Stack>
  </Margin>
);

export const NonPharmacSectionFooter = () => (
  <Margin top={{ xs: 4, md: 6 }}>
    <LinkWrapper>
      *<Link href="#things-to-know">Important things to know</Link>
    </LinkWrapper>
    <Copy measure={false}>
      <sup>1</sup> Non-PHARMAC treatment at home will be covered for up to six months after you’re
      admitted to private hospital for approved and related treatment.
    </Copy>
  </Margin>
);
