import React from 'react';
import { Section, Stack } from '@nib/layout';
import { PrimaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { urlConstants } from '../../constructs/constants';
import GetCovered343w from '../../img/compare-plans/get-covered343x200.jpg';
import GetCovered600w from '../../img/compare-plans/get-covered600x590.jpg';
import ListCopy from '../../components/ListCopy';
import TwoLaneSection from '../../components/TwoLaneSection';

export const GetCoveredSection = () => (
  <Section role="section">
    <TwoLaneSection images={{ desktop: GetCovered600w, mobile: GetCovered343w }}>
      <TwoLaneSection.Text>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            Get your health covered now
          </Heading>
          <Copy measure={false}>
            Joining nib and getting your health covered is easy. You can even do it all online. Just
            follow these simple steps:
          </Copy>
          <ListCopy measure={false} component="div">
            <ol>
              <li>Enter your details</li>
              <li>
                Add everyone you want to get cover for, such as your partner, spouse and any
                children
              </li>
              <li>
                Choose your plan. If you're getting Hospital cover, choose your excess from the
                options available to you
              </li>
              <li>Choose a quote and how often you want to pay</li>
              <li>Enter your payment details and finalise your plan</li>
            </ol>
          </ListCopy>
        </Stack>
      </TwoLaneSection.Text>
      <TwoLaneSection.Subtext>
        <PrimaryButton href={urlConstants.getQuickQuote()}>Get covered</PrimaryButton>
      </TwoLaneSection.Subtext>
    </TwoLaneSection>
  </Section>
);
