import { ContentfulDocument } from '../constructs/models';
import { urlConstants } from '../constructs/constants';

const combineBenefits = (
  standardHospitalPolicyDocument: ContentfulDocument,
  premiumHospitalPolicyDocument: ContentfulDocument,
  standardEverydayPolicyDocument: ContentfulDocument,
  premiumEverydayPolicyDocument: ContentfulDocument
) => [
  {
    type: 'plans',
    columns: [
      {
        name: 'Standard Hospital + Standard Everyday',
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        benefits: [
          {
            productCode: 'IZE',
            subProductCode: 'BASE-IZE',
            membershipCode: 'IZ',
            excess: 0,
          },
          {
            productCode: 'IZH',
            subProductCode: 'BASE-IZH',
            membershipCode: 'IZ',
            excess: 250,
          },
        ],
      },
      {
        name: 'Premium Hospital + Premium Everyday',
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        benefits: [
          {
            productCode: 'IZF',
            subProductCode: 'BASE-IZF',
            membershipCode: 'IZ',
            excess: 0,
          },
          {
            productCode: 'IZP',
            subProductCode: 'BASE-IZP',
            membershipCode: 'IZ',
            excess: 250,
          },
        ],
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Overall Hospital benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Surgical related benefits',
        bothColumns: {
          summary: "<div>Up to:</div><div style='font-size: 28px;'>$300,000</div>",
          details:
            '<p><b>Hospital surgical benefit limit: $300,000</b></p><p>Maximum amount for surgery related benefits.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Non-surgical related benefits',
        bothColumns: {
          summary: "<div>Up to:</div><div style='font-size: 28px;'>$200,000</div>",
          details:
            '<p><b>Non-surgical benefit limit: $200,000</b></p><p>Maximum amount for non-surgical related benefits.</p>',
        },
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Hospital benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Cancer Treatment',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for and relating to cancer treatment including:</p><ul><li>Chemotherapy</li><li>Radiotherapy</li><li>Immunotherapy</li><li>Brachytherapy</li><li>Hospital accommodation (e.g. admitted patient’s bed, a private room)</li><li>In hospital X-ray examination and ECG</li><li>Intensive post treatment care and special in hospital nursing</li><li>In hospital post treatment physiotherapy</li><li>Ancillary hospital charges (e.g. dressings, needles, bandages)</li><li>Hospital pharmaceutical prescriptions</li></ul><p>We pay up to the Non-Surgical benefit limit.</p><p>Cancer surgery is covered under the Surgical Benefit.</p><p>PHARMAC funded chemotherapy drugs for the treatment of cancer at home are covered under the Cancer Treatment Benefit, for which we pay up to $10,000 each year.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Surgery in hospital',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p><b>Benefit: Up to $300,000</b></p><p>Cover for surgery in a private hospital, including costs for:</p><ul><li>Surgeon’s operating fees.</li><li>Anaesthetist’s fees.</li><li>Operating theatre fee.</li><li>Surgically implanted prosthesis (in line with the prosthesis schedule).</li><li>In-hospital pharmaceutical prescriptions.</li><li>Hospital accommodation (e.g. the admitted patient’s bed, a private room, excludes suites).</li><li>Oral surgery</li><li>Melanoma surgery</li><li>Varicose vein treatment</li></ul>',
        },
      },
      {
        type: 'benefit',
        category: 'Non-surgical hospitalisation',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for medical treatment (not involving surgery) in a private hospital in relation to a medical condition (e.g. heart disease, asthma, pneumonia or diabetes).</p><p>We also cover the cost of associated intensive nursing care, X-rays, disposables and consumables, dressings and in-hospital pharmaceutical prescriptions. In addition, this benefit covers less invasive procedures as an alternative to surgery.</p><p>We pay up to the Non-Surgical Benefit limit.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Specialist consultations',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Cover for specialist or vocational GP consultations up to 6 months before and after a related hospital admission.</p><p>We pay up to the Surgical or Non-Surgical Benefit limit (whichever applies).</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit is more comprehensive on Premium Hospital than Standard Hospital, see below.</p><p>Cover for specialist or vocational GP consultations whether or not it relates to an admission to a recognised private hospital.</p><p>We pay up to the Surgical or Non-Surgical Benefit limit (whichever applies).</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Diagnostic investigations',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p>Cover for diagnostic investigations up to 6 months before and after a related hospital admission.</p><p>We pay up to the Surgical or Non-Surgical Benefit limit (whichever applies).</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit is more comprehensive on Premium Hospital than Standard Hospital, see below.</p><p>Cover for diagnostic investigations, whether or not they relate to admission to a private hospital.</p><p>For diagnostics not related to a hospital admission, we cover</p><ul><li>up to $5,000 for CT, MRI and PET scans and</li><li>up to $15,000 for all other diagnostics</li></ul><p>For diagnostic investigations related to a hospital admission we pay up to the Surgical or Non-Surgical Benefit limit (whichever applies).</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Follow-up investigations for cancer',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p><b>Benefit: Up to $3,000*</b></p><p></p><p>Cover for one specialist consultation each year and related investigations following cancer treatment.</p><p>*We pay up to $3,000 per year for up to five consecutive years.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Travel and accommodation',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for travel costs for you and a support person, and accommodation costs for a support person when you need to travel further than 100km from where you live for treatment.</p><p>*We pay up to $300 per night for accommodation, up to $3,000 per policy year for travel related to surgery or non-surgical treatment, and up to your Surgical or Non-Surgical Benefit limit for travel related to cancer treatment.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'GP surgery',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p><b>Benefit: Up to $750</b></p><p>Cover for any surgery performed by a GP, including one pre and one post consultation and any related biopsy.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'ACC top up',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for the difference in costs between what is paid by ACC for an injury and the actual costs.</p><p>We pay up to the Surgical or Non-Surgical Benefit limit (whichever applies).</p>',
        },
      },
      {
        type: 'benefit',
        category: 'ACC Treatment Injury',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for reparative treatment for any injury that  occurs during treatment we’ve paid for that isn’t covered by ACC.</p><p>We pay up to the Surgical or Non-Surgical Benefit limit (whichever applies).</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Ambulance transfer',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for road ambulance transfer from a public or private hospital to the closest private hospital.</p><p>We pay up to the Surgical or Non-Surgical Benefit limit (whichever applies).</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Post-hospital physiotherapy',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $500</b></p><p>Cover for physiotherapy consultations and treatment up to six months after discharge from private hospital.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Premium Hospital than Standard Hospital. See below.</p><p><b>Benefit: Up to $750</b></p><p>Cover for physiotherapy consultations and treatment up to six months after discharge from private hospital.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Specialist skin lesion surgery',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $2,500</b></p><p>Cover for skin lesion surgery performed by a specialist.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Premium Hospital than Standard Hospital. See below.</p><p><b>Benefit: Up to $6,000</b></p><p>Cover for skin lesion surgery performed by a specialist.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Foot surgery',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p><b>Benefit: Up to $6,000</b></p><p>Cover for surgery performed by a podiatric surgeon under local anaesthetic, including up to one pre and one post-surgery consultation and related x-rays.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Parent accommodation',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $1,000*</b></p><p>Cover for accommodation costs for a parent or legal guardian accompanying an insured person age 20 and under who is being treated in a private hospital.</p><p>*We pay up to $200 per night for parent or legal guardian accommodation, up to a maximum of $1,000.</p>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Premium Hospital than Standard Hospital. See below.</p><p><b>Benefit: Up to $3,000*</b></p><p>Cover for accommodation costs for a parent or legal guardian accompanying an insured person age 20 and under who is being treated in a private hospital.</p><p>*We pay up to $200 per night for parent or legal guardian accommodation, up to a maximum of $3,000.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Pre-existing cover for newborns',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for pre-existing conditions (except congenital conditions) for dependent children when added to the policy within four months of birth.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Funeral support benefit',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p><b>Benefit: Up to $3,000</b></p><p>We provide a payment if an insured person dies between the age of 16 and 64 (inclusive).</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Gym and sports no claims bonus',
        columns: [
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $150 after each 24 month period of continuous cover</b></p><p><b>Waiting Period:</b></p><p>This benefit is payable if no claims were paid in the preceding 24 month period.</p><p>Reimbursement towards the following:</p><ul><li>membership of a gym or sports club; or</li><li>sports/fitness equipment purchased from a sporting retailer.</li></ul>',
          },
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>TIP:</b> This benefit has a higher limit on Premium Hospital than Standard Hospital. See below.</p><p><b>Benefit: Up to $300 after each 24 month period of continuous cover</b></p><p><b>Waiting Period:</b></p><p>This benefit is payable if no claims were paid in the preceding 24 month period.</p><p>Reimbursement towards the following:</p><ul><li>membership of a gym or sports club; or</li><li>sports/fitness equipment purchased from a sporting retailer.</li></ul>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Waiver of premium',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p>Cover for the costs of premiums for remaining people on the policy if the policy owner dies before age 70.  We pay for up to 2 years or until any remaining people on the policy turn 70.</p>',
        },
      },
      {
        type: 'benefit',
        category: 'Suspension of cover',
        bothColumns: {
          summaryIcon: 'TickSystemIcon',
          details:
            '<p><b>Waiting Period:</b></p>' +
            '<p>12 months</p><p>You can put your cover on hold for:</p>' +
            '<ul><li>Up to six months due to unemployment or redundancy; or</li>' +
            '<li>Up to 24 months for travel or residence out New Zealand; or</li>' +
            '<li>Up to 12 months for parental leave.</li></ul>',
        },
      },
      {
        type: 'benefit',
        category: 'Non-PHARMAC Cancer treatment',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $20,000*</b></p><p>Cover for non-PHARMAC chemotherapy and immunotherapy medicines that are Medsafe approved for the treatment of cancer.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Obstetrics',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $1,500 per pregnancy</b></p><p><b>Waiting Period:</b></p><p>12 months</p><p>Cover for the cost of treatment by an obstetrician.  We cover treatment that happens during pregnancy, but we don’t cover IVF, caesarean sections, treating ectopic pregnancies or public hospital admissions.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Eye injections',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $3,000</b></p><p>Cover for intravitreal eye injections administered by a specialist, after referral by a GP or specialist.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Post-hospital therapeutic care',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $250</b></p><p>Cover for the cost of the following up to six months after being discharged from private hospital:</p><ul><li>osteopathic treatment.</li><li>chiropractic treatment.</li><li>sports physician treatment.</li><li>speech therapy.</li><li>occupational therapy; and </li><li>dietitian consultations.</li></ul>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Post-hospital home care',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $6,000*</b></p><p>Cover for the cost of home care for up to 6 months after being discharged from private hospital.</p><p>*We pay up to $150 per day for home care, up to a maximum of $6,000.</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Sterilisation',
        columns: [
          {},
          {
            summaryIcon: 'TickSystemIcon',
            details:
              '<p><b>Benefit: Up to $1,000 per lifetime</b></p><p><b>Waiting period:</b></p><p>Two years</p><p>Cover for the cost of male or female sterilisation as a means of contraception.</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'header',
    columns: [
      {
        heading: 'Everyday benefits (Benefit limits are per person, per year):',
      },
      {
        heading: '',
      },
      {
        heading: '',
      },
    ],
  },
  {
    type: 'benefits',
    items: [
      {
        type: 'benefit',
        category: 'Dental – including orthodontics',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$750<div>",
            details:
              '<p>Dental treatments including:</p><ul><li>examinations</li><li>cleaning and scaling</li><li>fillings</li><li>extractions (including wisdom teeth)</li><li>associated x-rays</li><li>orthodontic treatment (e.g. braces)</li><li>endodontic treatment (e.g. root canal)</li></ul><p><b>Waiting periods:</b></p><p><b>Preventative:</b></p><p>2 months (e.g. examinations, scale and clean, fluoride treatments)</p><p><b>General:</b></p><p>2 months (e.g. fillings, basic extraction (excluding wisdom teeth), x-rays)</p><p><b>Major &amp; orthodontic:</b></p><p>12 months (e.g. crowns, bridges, root canal, removal of wisdom teeth, dentures, implants)</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$1000<div>",
            details:
              '<p>Dental treatments including:</p><ul><li>examinations</li><li>cleaning and scaling</li><li>fillings</li><li>extractions (including wisdom teeth)</li><li>associated x-rays</li><li>orthodontic treatment (e.g. braces)</li><li>endodontic treatment (e.g. root canal)</li></ul><p><b>Waiting periods:</b></p><p><b>Preventative:</b></p><p>2 months (e.g. examinations, scale and clean, fluoride treatments)</p><p><b>General:</b></p><p>2 months (e.g. fillings, basic extraction (excluding wisdom teeth), x-rays)</p><p><b>Major &amp; orthodontic:</b></p><p>12 months (e.g. crowns, bridges, root canal, removal of wisdom teeth, dentures, implants)</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Eye care',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of prescription eyewear, and the cost of examination fees</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of prescription eyewear, and the cost of examination fees</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'GP visits & prescriptions',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of GP and nurse practitioner visits and prescriptions.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of GP and nurse practitioner visits and prescriptions.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Physio, chiro & osteo',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of physiotherapy, chiropractic or osteopathic treatment.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of physiotherapy, chiropractic or osteopathic treatment.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Acupuncture, traditional Chinese medicine, remedial massage and vaccinations',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$150<div>",
            details:
              '<p>Covers the cost of acupuncture, traditional Chinese medicine, remedial massage and vaccinations.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$300<div>",
            details:
              '<p>Covers the cost of acupuncture, traditional Chinese medicine, remedial massage and vaccinations.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Speech therapy',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$200<div>",
            details:
              '<p>Covers the cost of speech therapy.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$400<div>",
            details:
              '<p>Covers the cost of speech therapy.</p><p><b>Waiting period:</b></p><p>2 months</p>',
          },
        ],
      },
      {
        type: 'benefit',
        category: 'Mental health consultations',
        columns: [
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$350<div>",
            details:
              '<p>Covers the cost of consultations by a psychiatrist or psychologist.</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
          {
            summary: "<div style='padding-top: 5px; font-size: 28px;'>$500<div>",
            details:
              '<p>Covers the cost of consultations by a psychiatrist or psychologist.</p><p><b>Waiting period:</b></p><p>6 months</p>',
          },
        ],
      },
    ],
  },
  {
    type: 'footer',
    columns: [
      {
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        documents: [
          {
            title: 'Standard Hospital Cover Policy document',
            url: standardHospitalPolicyDocument?.file?.url,
          },
          {
            title: 'Standard Everyday Cover Policy document',
            url: standardEverydayPolicyDocument?.file?.url,
          },
        ],
      },
      {
        buttonText: 'Get a quote',
        buttonUrl: urlConstants.getQuickQuote(),
        documents: [
          {
            title: 'Premium Hospital Cover Policy document',
            url: premiumHospitalPolicyDocument?.file?.url,
          },
          {
            title: 'Premium Everyday Cover Policy document',
            url: premiumEverydayPolicyDocument?.file?.url,
          },
        ],
      },
    ],
  },
];

export default combineBenefits;
