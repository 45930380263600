import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Padding } from 'styled-components-spacing';
import {
  HealthFocusGraphicIcon,
  HealthInsuranceGraphicIcon,
  ApprovalGraphicIcon,
  DiscountGraphicIcon,
  SavingsGraphicIcon,
} from '@nib/icons';
import { Box, Section } from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import { copyColor } from '@nib-components/theme';

const BoxWrapper = styled(Box)`
  margin: 12px 0px;
  ${breakpoint('md')`
    flex-basis: 48%;
    margin: 1%;
  `};
  ${breakpoint('lg')`
    flex-basis: 31%;
    margin: 1%;
  `};
`;

export const Content = styled.div`
  display: inline-block;
  max-width: 30em;
  margin: auto;
  font-family: ${({ theme }) => (theme.fonts && theme.fonts.copy) || 'sans-serif'};
  color: ${copyColor};
`;

const BenefitsFeaturePanel = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${breakpoint('md')`
    margin: 0px -12px;
  `};
`;

interface FeatureTileProps {
  icon: any;
  title: string;
  children: JSX.Element;
}

const FeatureTile = ({ icon: Icon, title, children }: FeatureTileProps): JSX.Element => (
  <BoxWrapper padding={5} paddingVertical={{ lg: 6 }} textAlign="center" background="white">
    <Icon size="xl" fill="trueGreen" />
    <Padding top={4}>
      <Box textAlign="center">
        <Heading size={4} component="h3" color="trueGreen">
          {title}
        </Heading>
        <Content>{children}</Content>
      </Box>
    </Padding>
  </BoxWrapper>
);

export const BuyingOnlineBenefitSection = (): JSX.Element => (
  <Section role="section" background="warmWhite60">
    <Box display="flex" justifyContent="center" marginBottom={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
        The benefits of buying online
      </Heading>
    </Box>
    <BenefitsFeaturePanel>
      <FeatureTile icon={HealthFocusGraphicIcon} title="No health questions">
        <Padding top={4}>
          <Copy>
            You don't have to complete any medical or health questions, join online in less than 10
            minutes.
          </Copy>
        </Padding>
      </FeatureTile>
      <FeatureTile icon={HealthInsuranceGraphicIcon} title="Immediate cover">
        <Padding top={4}>
          <Copy>
            Your cover starts straight away, but waiting periods will apply for some benefits
            <sup>1</sup>.
          </Copy>
        </Padding>
      </FeatureTile>
      <FeatureTile icon={ApprovalGraphicIcon} title="Cover for pre-existing conditions">
        <Padding top={4}>
          <Copy>
            On a Hospital plan, many of your pre-existing conditions can be covered after 3 years
            <sup>**</sup>.
          </Copy>
        </Padding>
      </FeatureTile>
      <FeatureTile icon={DiscountGraphicIcon} title="Flexible excess">
        <Padding top={4}>
          <Copy>
            On a Hospital plan, you can choose an excess option that suits your budget – a higher
            excess means a lower premium.
          </Copy>
        </Padding>
      </FeatureTile>
      <FeatureTile icon={SavingsGraphicIcon} title="Claim for everyday costs">
        <Padding top={4}>
          <Copy>
            On an Everyday plan, you can claim back a percentage of your day-to-day health costs
            <sup>2</sup>.
          </Copy>
        </Padding>
      </FeatureTile>
    </BenefitsFeaturePanel>
    <Box marginTop={6}>
      <Copy measure={false}>
        <sup>1</sup> on Hospital plans, some pre-existing conditions will only be covered after
        three years and others will never be covered. Benefit limits and policy terms apply.
      </Copy>
      <Copy measure={false}>
        <sup>2</sup> up to the benefit limit and in line with your policy terms.
      </Copy>
      <Copy measure={false}>
        <sup>**</sup> <Link href="#things-to-know">Find out more</Link>
      </Copy>
    </Box>
  </Section>
);
