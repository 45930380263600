import React from 'react';
import { Padding, Margin } from 'styled-components-spacing';
import {
  MoreGraphicIcon,
  ToothShieldGraphicIcon,
  StethoscopeGraphicIcon,
  GlassesGraphicIcon,
  RemedialMassageGraphicIcon,
  PhysioGraphicIcon,
} from '@nib/icons';

import { Section, Tiles } from '@nib/layout';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';
import BenefitsTable, { LinkWrapper } from '../../../components/BenefitsTable';
import IconPoint from '../../../components/IconPoint';
import everydayBenefits from '../../../content/everyday-benefits';

const EverydayPlanDescription = (): JSX.Element => (
  <Padding bottom={6}>
    <Copy measure={false}>
      Our Everyday plans are designed to contribute towards your day-to-day healthcare costs and to
      make it easy for you to be proactive and stay healthy. These plans offer cover for:
    </Copy>
  </Padding>
);

const EverydayCoveredItems = (): JSX.Element => (
  <Margin bottom={6}>
    <Tiles columns={{ xs: 1, md: 2 }} space={{ xs: 4, md: 6 }}>
      <IconPoint
        largeText={false}
        icon={ToothShieldGraphicIcon}
        description="Dental treatment like check-ups, fillings, braces and crowns"
      />
      <IconPoint largeText={false} icon={StethoscopeGraphicIcon} description="GP visits" />
      <IconPoint
        largeText={false}
        icon={GlassesGraphicIcon}
        description="New glasses and contact lenses"
      />
      <IconPoint
        largeText={false}
        icon={RemedialMassageGraphicIcon}
        description="Acupuncture and massage"
      />
      <IconPoint largeText={false} icon={PhysioGraphicIcon} description="Physio" />
      <IconPoint largeText={false} icon={MoreGraphicIcon} description="Plus a whole lot more" />
    </Tiles>
  </Margin>
);

export const EverydaySection = ({
  standardEverydayPolicyDocument,
  premiumEverydayPolicyDocument,
}): JSX.Element => (
  <Section>
    <EverydayPlanDescription />
    <EverydayCoveredItems />
    <BenefitsTable
      rows={everydayBenefits(standardEverydayPolicyDocument, premiumEverydayPolicyDocument)}
      numberOfColumns={2}
    />
    <Margin top={{ xs: 4, md: 6 }}>
      <LinkWrapper>
        <Link href="#things-to-know">*Important things to know</Link>
      </LinkWrapper>
    </Margin>
  </Section>
);
