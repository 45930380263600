import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { useQueryParam, StringParam } from 'use-query-params';
import { PrimaryButton } from '@nib-components/button';
import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import { HeroPanelCopy } from '../components/styledComponents';
import { urlConstants } from '../constructs/constants';
import { ContentfulDocument } from '../constructs/models';
import metrics from '../metrics';
import desktop from '../img/compare-plans/hero/desktop.jpg';
import desktop2x from '../img/compare-plans/hero/desktop@2x.jpg';
import mobile from '../img/compare-plans/hero/mobile.jpg';
import mobile2x from '../img/compare-plans/hero/mobile@2x.jpg';
import tablet from '../img/compare-plans/hero/tablet.jpg';
import tablet2x from '../img/compare-plans/hero/tablet@2x.jpg';
import {
  BuyingOnlineBenefitSection,
  ThingsToKnowSection,
  GetCoveredSection,
  CallBackSection,
  PlanSection,
  NavigationSection,
} from '../page-sections/compare-plans';

export const ComparePlansPageQuery = graphql`
  query ComparePlansPageQuery {
    standardEverydayPolicyDocument: allContentfulDocument(
      filter: { name: { eq: "Standard Everyday Policy Document" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    premiumEverydayPolicyDocument: allContentfulDocument(
      filter: { name: { eq: "Premium Everyday Policy Document" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    standardHospitalPolicyDocument: allContentfulDocument(
      filter: { name: { eq: "Standard Hospital Policy Document" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    premiumHospitalPolicyDocument: allContentfulDocument(
      filter: { name: { eq: "Premium Hospital Policy Document" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    nonPharmacPlusTermsAndConditions: allContentfulDocument(
      filter: { name: { eq: "non-PHARMAC Plus add-on DTC" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

interface GraphQLDocumentNode {
  edges: ContentfulDocumentEdgeShape[];
}

interface ContentfulDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface ComparePlansProps {
  data: {
    standardEverydayPolicyDocument: GraphQLDocumentNode;
    premiumEverydayPolicyDocument: GraphQLDocumentNode;
    standardHospitalPolicyDocument: GraphQLDocumentNode;
    premiumHospitalPolicyDocument: GraphQLDocumentNode;
    nonPharmacPlusTermsAndConditions: GraphQLDocumentNode;
  };
}

const getContentfulDocument = (key: keyof ComparePlansProps['data'], props: ComparePlansProps) => {
  return props?.data?.[key]?.edges?.[0]?.node?.document;
};

const ComparePlansPage = (props: ComparePlansProps): JSX.Element => {
  const standardEverydayPolicyDocument = getContentfulDocument(
    'standardEverydayPolicyDocument',
    props
  );
  const premiumEverydayPolicyDocument = getContentfulDocument(
    'premiumEverydayPolicyDocument',
    props
  );
  const standardHospitalPolicyDocument = getContentfulDocument(
    'standardHospitalPolicyDocument',
    props
  );
  const premiumHospitalPolicyDocument = getContentfulDocument(
    'premiumHospitalPolicyDocument',
    props
  );
  const nonPharmacPlusTermsAndConditionsDocument = getContentfulDocument(
    'nonPharmacPlusTermsAndConditions',
    props
  );
  const [plan] = useQueryParam('plan', StringParam);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (plan === 'hospital') {
        document.getElementById('hospital-tab')?.click();
      } else if (plan === 'everyday') {
        document.getElementById('everyday-tab')?.click();
      } else if (plan === 'combine') {
        document.getElementById('combine-tab')?.click();
      }
    }
  });

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'https://www.nib.co.nz/compare-plans',
    headline: 'Compare health insurance plans from dental to surgery covers | nib',
    identifier: 'https://www.nib.co.nz/compare-plans',
    sameAs: [
      'https://en.wikipedia.org/wiki/Health_insurance',
      'https://en.wikipedia.org/wiki/Healthcare_in_New_Zealand',
    ],
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>Compare Health Insurance Plans | nib</title>
        <meta
          name="description"
          content="Health insurance isn’t just for those who are sick. Compare Everyday cover for dental, GP consults, prescription glasses and more."
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <HeroPanel
        images={{
          desktop: desktop,
          desktop2x: desktop2x,
          mobile: mobile,
          mobile2x: mobile2x,
          tablet: tablet,
          tablet2x: tablet2x,
        }}
        title="Compare Health Insurance"
        variation="condensed"
      >
        <HeroPanelCopy>
          Have peace of mind with our range of health insurance plans available online or ask your
          independent financial adviser for other options.
        </HeroPanelCopy>
        <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
      </HeroPanel>
      <PlanSection
        standardHospitalPolicyDocument={standardHospitalPolicyDocument}
        premiumHospitalPolicyDocument={premiumHospitalPolicyDocument}
        standardEverydayPolicyDocument={standardEverydayPolicyDocument}
        premiumEverydayPolicyDocument={premiumEverydayPolicyDocument}
        nonPharmacPlusTermsAndConditionsDocument={nonPharmacPlusTermsAndConditionsDocument}
      />
      <BuyingOnlineBenefitSection />
      <GetCoveredSection />
      <a id="callback" />
      <CallBackSection />
      <a id="things-to-know" />
      <ThingsToKnowSection
        standardHospitalPolicyDocument={standardHospitalPolicyDocument}
        premiumHospitalPolicyDocument={premiumHospitalPolicyDocument}
        standardEverydayPolicyDocument={standardEverydayPolicyDocument}
        premiumEverydayPolicyDocument={premiumEverydayPolicyDocument}
      />
      <NavigationSection />
    </Layout>
  );
};

export default metrics({ pageName: 'compare-plans' })(ComparePlansPage);
