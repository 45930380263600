import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { Box, Section, Stack } from '@nib/layout';
import Accordion from '@nib-components/accordion';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import { colorTrueGreen } from '@nib-components/theme';
import ListCopy from '../../components/ListCopy';

const StyledAccordion = styled(Accordion)`
  p {
    color: ${colorTrueGreen};
  }
`;

const StyledHeading = styled(Heading)`
  font-size: 20px;
`;

export const ThingsToKnowSection = ({
  standardHospitalPolicyDocument,
  premiumHospitalPolicyDocument,
  standardEverydayPolicyDocument,
  premiumEverydayPolicyDocument,
}): JSX.Element => (
  <Section role="section">
    <Stack space={{ xs: 4, md: 6 }}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
        Things to know before you join
      </Heading>
      <Box>
        <Margin bottom={2}>
          <StyledHeading color="trueGreen" size={4}>
            *Our pricing
          </StyledHeading>
        </Margin>
        <Copy measure={false}>
          The price you see online includes our 4% direct debit discount and is based on a 25 year
          old male, non-smoker with a $250 annual excess on Hospital cover. We recommend getting a
          quote specific to you so you know what you will need to pay.
        </Copy>
        <Margin top={2}>
          <Copy measure={false}>
            The price you see online for non-PHARMAC Plus includes our 4% direct debit discount and
            is based on a 25 year old male, non-smoker. We recommend getting a quote specific to you
            so you know what you will need to pay.
          </Copy>
        </Margin>
      </Box>
      <Box>
        <Margin bottom={2}>
          <StyledHeading color="trueGreen" size={4}>
            **Am I covered for any pre-existing conditions?
          </StyledHeading>
        </Margin>
        <Copy measure={false}>
          In summary, a pre-existing condition is any injury, illness, symptom or diagnosis that
          happened any time prior to your join date. Many pre-existing conditions will be covered
          after three years, as explained in the policy document. However, we will never cover any
          of the following if they are pre-existing:
        </Copy>
        <ListCopy measure={false} component="div">
          <ul>
            <li>Cancer</li>
            <li>Cardiovascular conditions</li>
            <li>Hip, knee or back issues</li>
            <li>Transplant surgery</li>
            <li>Reconstructive or reparative surgery</li>
          </ul>
        </ListCopy>
      </Box>
      <StyledAccordion borderTop={false} borderBottom>
        <Accordion.Item title="Are you eligible?" titleComponent="p">
          <Copy measure={false}>
            All New Zealand citizens and permanent residents living in New Zealand are welcome to
            join nib.
          </Copy>
          <ListCopy measure={false} component="div">
            <p>
              For anyone living in New Zealand, you can join nib with our Everyday plans. Our
              Hospital plans are only available for those who are:
            </p>
            <ul>
              <li>
                An Australian citizen or permanent resident who has lived, or intends to live, in
                New Zealand for two years or more; or
              </li>
              <li>
                Permanently employed in New Zealand and hold a current New Zealand work visa that
                has been issued for at least 2 years with at least 12 months remaining; or
              </li>
              <li>
                People who aren’t on a work visa but are otherwise entitled to publicly funded
                health and disability services by the New Zealand Government. You can read a full
                guide around{' '}
                <Link
                  href="https://www.health.govt.nz/new-zealand-health-system/eligibility-publicly-funded-health-services/guide-eligibility-publicly-funded-health-services"
                  target="_blank"
                >
                  public healthcare eligibility
                </Link>{' '}
                on the official government site.
              </li>
            </ul>
          </ListCopy>
        </Accordion.Item>
        <Accordion.Item title="Check the detail" titleComponent="p">
          <Copy measure={false}>
            Only the main features and benefits of our nib Hospital and Everyday plans are outlined
            here. For a full explanation of all benefits and exclusions, view a Policy Document:
          </Copy>
          <ListCopy measure={false} component="div">
            <ul>
              <li>
                <Link href={standardEverydayPolicyDocument?.file?.url} target="_blank">
                  Standard Everyday Cover
                </Link>
              </li>
              <li>
                <Link href={premiumEverydayPolicyDocument?.file?.url} target="_blank">
                  Premium Everyday Cover
                </Link>
              </li>
              <li>
                <Link href={standardHospitalPolicyDocument?.file?.url} target="_blank">
                  Standard Hospital Cover
                </Link>
              </li>
              <li>
                <Link href={premiumHospitalPolicyDocument?.file?.url} target="_blank">
                  Premium Hospital Cover
                </Link>
              </li>
            </ul>
          </ListCopy>
        </Accordion.Item>
        <Accordion.Item title="Tailored premiums for multiple people" titleComponent="p">
          <Copy measure={false}>
            We calculate premiums for each member based on several factors. These can include: age,
            gender, whether you smoke or not, the level of cover you want and the excess you’ve
            chosen to pay.
          </Copy>
        </Accordion.Item>
        <Accordion.Item title="You're not locked in" titleComponent="p">
          <Copy measure={false}>
            You get a 14-day free-look period when you join, to help you decide whether your plan is
            a good fit. We’ll refund any premiums you’ve paid if you decide the plan isn’t for you
            in that first 14 days (and if you haven’t made any claims). If you ever feel your health
            cover is no longer what you need, we can talk you through your options.
          </Copy>
        </Accordion.Item>
      </StyledAccordion>
    </Stack>
  </Section>
);
