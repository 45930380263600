import React from 'react';
import styled from 'styled-components';
import { Padding } from 'styled-components-spacing';
import { Tabs, TabList, TabPanel } from '@nib-components/tabs';
import { colorWhite } from '@nib-components/theme';
import { Tab } from '../../../components/styledComponents';
import { ContentfulDocument } from '../../../constructs/models';
import { HospitalSection } from './HospitalSection';
import { EverydaySection } from './EverydaySection';
import { CombinePlansSection } from './CombinePlansSection';
import { HospitalAddOnSection } from './HospitalAddOnSection';

const StyledTabList = styled(TabList)`
  li:not(.react-tabs__tab--selected) {
    color: ${colorWhite};
  }
`;

interface PlanSectionProps {
  standardHospitalPolicyDocument: ContentfulDocument;
  premiumHospitalPolicyDocument: ContentfulDocument;
  standardEverydayPolicyDocument: ContentfulDocument;
  premiumEverydayPolicyDocument: ContentfulDocument;
  nonPharmacPlusTermsAndConditionsDocument: ContentfulDocument;
}

export const PlanSection = ({
  standardHospitalPolicyDocument,
  premiumHospitalPolicyDocument,
  standardEverydayPolicyDocument,
  premiumEverydayPolicyDocument,
  nonPharmacPlusTermsAndConditionsDocument,
}: PlanSectionProps): JSX.Element => (
  <Tabs role="section" defaultIndex={0}>
    <StyledTabList center={true} variation="container" background="warmWhite60">
      <Tab subtitle="Cover to help pay for the big things">
        <span id="hospital-tab">Hospital plan</span>
      </Tab>
      <Tab subtitle="Cover for your day-to-day health costs">
        <span id="everyday-tab">Everyday plan</span>
      </Tab>
      <Tab subtitle="Get cover from both plans">
        <span id="combine-tab">Combine plans</span>
      </Tab>
      <Tab subtitle="Options to add to your Hospital plan">
        <span id="hospital-tab">Hospital add-ons</span>
      </Tab>
    </StyledTabList>
    <TabPanel>
      <Padding vertical={{ xs: 3, md: 4 }}>
        <HospitalSection
          standardHospitalPolicyDocument={standardHospitalPolicyDocument}
          premiumHospitalPolicyDocument={premiumHospitalPolicyDocument}
          nonPharmacPlusTermsAndConditionsDocument={nonPharmacPlusTermsAndConditionsDocument}
        />
      </Padding>
    </TabPanel>
    <TabPanel>
      <Padding vertical={{ xs: 3, md: 4 }}>
        <EverydaySection
          standardEverydayPolicyDocument={standardEverydayPolicyDocument}
          premiumEverydayPolicyDocument={premiumEverydayPolicyDocument}
        />
      </Padding>
    </TabPanel>
    <TabPanel>
      <Padding vertical={{ xs: 3, md: 4 }}>
        <CombinePlansSection
          standardHospitalPolicyDocument={standardHospitalPolicyDocument}
          premiumHospitalPolicyDocument={premiumHospitalPolicyDocument}
          standardEverydayPolicyDocument={standardEverydayPolicyDocument}
          premiumEverydayPolicyDocument={premiumEverydayPolicyDocument}
          nonPharmacPlusTermsAndConditionsDocument={nonPharmacPlusTermsAndConditionsDocument}
        />
      </Padding>
    </TabPanel>
    <TabPanel>
      <Padding vertical={{ xs: 3, md: 4 }}>
        <HospitalAddOnSection
          nonPharmacPlusTermsAndConditionsDocument={nonPharmacPlusTermsAndConditionsDocument}
        />
      </Padding>
    </TabPanel>
  </Tabs>
);
