import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pb, pt, px } from 'styled-components-spacing';

const GetCoveredGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem1'
    'gridItem3'
    'gridItem2';
  ${breakpoint('lg')`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'gridItem1 gridItem3'
    'gridItem2 gridItem3'
`};
`;

const Text = styled.div`
  grid-area: gridItem1;
  ${breakpoint('lg')`
    ${px(4)};
    ${pb(0)};
  `};
`;

const Subtext = styled.div`
  grid-area: gridItem2;
  text-align: center;
  ${pt(4)};
  ${breakpoint('lg')`
    text-align: left;
    ${px(4)};
  `};
`;

const ImageContainer = styled.div`
  grid-area: gridItem3;
  display: grid;
  ${breakpoint('lg')`
    min-height: 475px;
  `};
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;

  ${breakpoint('lg')`
    width: auto;
    text-align: left;
  `};
`;

const InnerImage = styled.img`
  width: auto;
  ${pt(4)};

  ${breakpoint('lg')`
    position: ${(props) => (props.showEntireImage ? 'relative' : 'absolute')};
    ${pt(0)};
  `};
`;

const FixedWidthImage = styled(InnerImage)`
  ${breakpoint('lg')`
    width: 100%;
  `};
`;

type SplitActionSectionProps = {
  children: JSX.Element[];
  images: {
    desktop: string;
    mobile: string;
  };
  useFixedWidthImage?: boolean;
  showEntireImage?: boolean;
};

/**
 * Grid that will display three panels with middle shifting position responsively.
 *
 * Eg:
 * destop
 * | panel 1 | panel 3
 * | panel 2 |
 *
 * mobile
 * | panel 1 |
 * | panel 3 |
 * | panel 2 |
 *
 * @param props
 * @returns
 */
const TwoLaneSection = (props: SplitActionSectionProps) => (
  <GetCoveredGrid>
    {props.children}
    <ImageContainer>
      <ImageWrapper>
        <picture>
          <source media="(max-width:640px)" srcSet={`${props.images.mobile}`} />
          {props.useFixedWidthImage ? (
            <FixedWidthImage src={props.images.desktop} showEntireImage={props.showEntireImage} />
          ) : (
            <InnerImage src={props.images.desktop} showEntireImage={props.showEntireImage} />
          )}
        </picture>
      </ImageWrapper>
    </ImageContainer>
  </GetCoveredGrid>
);

TwoLaneSection.Text = Text;
TwoLaneSection.Subtext = Subtext;

export default TwoLaneSection;
